import { useContext, useEffect, useState } from "react";
import { SequenceContext } from "./SequenceContextProvider";
import NestedStepperForm from "./NestedStepperForm";
import { useLocation, useNavigate } from "react-router-dom";
import { WorkflowContext } from "../../../Context/WorkflowContext";
// import { candidateFormWorkflow } from '../WorkflowStepperForm/StepperFormJson'
import DummyStepper from '../CandidateCabinet/DummyStepper'

{
  /*

======================================================================================
                          CODE EXPLANATION
======================================================================================

  In the following code word 'sequence' refers array of arrays. Each array
  in list contains integers represent the active index for tab at each level.
  Indexing starts from '0' and increase in chronological order.

  Ex: [[0, 0], [0, 1], [1], [2, 0], [2, 1, 0]]

  In above example first array represent default active tab. The length of array
  represent number of tabs nested. In [0, 0] there are two tabs as:

  Tab 1
  Tab 1.1

======================================================================================
*/
}

const StepperForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Context API hook for getting current state of sequence context
  const { sequenceList } = useContext(SequenceContext);

  const {
    candidateFormWorkflow,
    isCandidateFormWorkflowLoading, getCandidateStepperFlow, getWorkflowSession } =
    useContext(WorkflowContext);
  console.log("candidateFormWorkflow:", candidateFormWorkflow);

  useEffect(() => {
    getCandidateStepperFlow(location.state?.candidate_id);
    getWorkflowSession(location.state?.candidate_id);
  }, []);

  return (
    <>
      {/* <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em className="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em className="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div> */}

      <div className="nk-block">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">

            {/* Render tabs here
      passing tabs array with initail level i.e 0 into Tab component */}
            {isCandidateFormWorkflowLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              sequenceList !== null &&
              sequenceList.length == 0 &&
              <div>No data Found</div>

            )}
            {sequenceList !== null &&
              sequenceList?.length !== 0 &&
              candidateFormWorkflow && (
                <DummyStepper
                  data={candidateFormWorkflow}
                  level={0}
                />
                // <NestedStepperForm
                //   data={candidateFormWorkflow.tabs}
                //   level={0}
                // />
              )}

            {/* Navigation buttons */}
            {/* <div className="container-fluid mt-5"> */}
            {/* Render Prev button only if there are tabs before */}
            {/* {level !== 0 && (
                  <button className="btn btn-secondary me-3" id='button-button-prevlevel' onClick={() => prevLevel()}>
                    Prev
                  </button>
                )} */}

            {/* If there are more tabs then show next
         If it is the final tab then show submit button */}
            {/* {sequenceList.length - 2 > level ? (
                  <button className="btn btn-secondary" id='button-button-nextlevel' onClick={() => nextLevel()}>
                    Next
                  </button>
                ) : (
                  <button className="btn btn-secondary" id='submit-button-stepperform' onClick={submitForm}>
                    Submit
                  </button>
                )}
              </div> */}

          </div>
        </div>
      </div>
    </>
  );
};
export default StepperForm;
