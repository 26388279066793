import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { VARIABLES } from '../../Constant';
import secureLocalStorage from 'react-secure-storage';
import WelcomeMessage from './WelcomeMessage';
import { googleLogout } from "@react-oauth/google";
import { NotificationsContext } from '../../Context/NotificationsContextProvider';
import moment from 'moment';
import { DashboardContext } from '../../Context/DashboardContext';

const Header = (props) => {
  const username = secureLocalStorage.getItem('username');
  const email = secureLocalStorage.getItem('email');
  const first_name = secureLocalStorage.getItem('firstName');
  const last_name = secureLocalStorage.getItem('lastName');
  const userID = secureLocalStorage.getItem("userID");

  const navigate = useNavigate();

  const {
    notifications,
    unreadNotificationsCount,
    handleNotificationStatus,
    updateNotificationStatus,
    setUnreadNotificationsCount,
    retryCount,
    getNotifications,
    getAllNotifications,
  } = useContext(NotificationsContext);

  const { rawPendingOnYou, getPendingOnYouData, isPendingOnYouLoading } = useContext(DashboardContext);



  const handleNavigation = (n) => {
    navigate(n.redirection_page_path)
  }

  // const handleNavigation = () => {
  //   console.log("first");
  //   navigate("/pendingonyou");
  // };

  // Calling logout function in app.js. State Pull up
  const handleLogout = () => {
    googleLogout();
    props.logoutHandler();
    navigate("/");
  };

  useEffect(() => {
    setUnreadNotificationsCount(notifications.length);
  }, [notifications]);

  useEffect(() => {
    getNotifications();
    getAllNotifications();
    getPendingOnYouData(userID);
  }, []);


  return (
    <>
      <div className="nk-header is-light nk-header-fixed is-light">
        <div className="container-xl wide-xl">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ms-n1 me-3">
              <a href="#" className="nk-nav-toggle nk-quick-nav-icon" id="button-button-sidebar_open" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a>
            </div>
            <div className="nk-header-brand d-xl-none">
              <a href={VARIABLES.fsc_redirection} target="_blank" className="logo-link" id="logo-fluidscapes">
                <img className="logo-light logo-img logo-img-xl" src="./assets/images/fsc_animated_logo_black.gif" srcSet="./assets/images/fsc_animated_logo_black.gif" alt="logo" />
                <img className="logo-dark logo-img logo-img-xl" src="./assets/images/fsc_animated_logo_black.gif" srcSet="./assets/images/fsc_animated_logo_black.gif" alt="logo-dark" />
              </a>

            </div>
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                {/* {privileges.includes("pending_on_you") ? ( */}
                <div class="drodown me-3">
                  <a
                    href="#"
                    class="dropdown-toggle btn"
                    data-bs-toggle="dropdown"
                    style={{ backgroundColor: "#058efc" }}
                  >
                    <span>Pending On You</span>
                    <em class="dd-indc icon ni ni-chevron-right" style={{ color: "#fff" }}></em>
                  </a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <ul
                      class="link-list-opt no-bdr"
                      style={{ maxHeight: "300px", overflowY: "auto" }}
                    >
                      {!isPendingOnYouLoading ? (
                        rawPendingOnYou?.length !== 0 ? (
                          rawPendingOnYou?.map((api) => {
                            return (
                              <li className="d-flex align-items-center pe-3">
                                <a className="dropdown-item" href="#">
                                  {api?.statistics_type}
                                </a>
                                <span className="badge bg-outline-gray">
                                  {api?.message}
                                </span>
                              </li>
                            );
                          })
                        ) : (
                          <>
                            <li className="d-flex align-items-center pe-3">
                              <span className="dropdown-item">No Data</span>
                            </li>
                          </>
                        )
                      ) : (
                        <>
                          <li className="d-flex align-items-center pe-3">
                            <span className="dropdown-item">Loading...</span>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
                {/* ) : (
                ""
              )} */}

                {/* {privileges.includes("new_ticket") ? ( */}
                <div class="dropdown notification-dropdown me-2">
                  <div
                    type="button"
                    className="dropdown-toggle btn-sm position-relative"
                    data-bs-toggle="dropdown"
                    data-bs-display="static"
                    data-bs-auto-close="outside"
                    style={{ fontSize: "1em" }}
                  >
                    <em class="icon ni ni-bell-fill fs-2"></em>
                    {unreadNotificationsCount !== 0 && (
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {unreadNotificationsCount <= 99
                          ? unreadNotificationsCount.toString()
                          : "99+"}
                        <span className="visually-hidden">unread messages</span>
                      </span>
                    )}
                  </div>
                  <div
                    class="dropdown-menu dropdown-menu-xl dropdown-menu-end"
                    style={{ height: "fit-content" }}
                  >
                    <div class="dropdown-head">
                      <span class="sub-title nk-dropdown-title">Notifications</span>
                      <span
                        type="button"
                        className="btn btn-sm btn-outline-primary border-0"
                        onClick={updateNotificationStatus}
                      >
                        <em class="icon ni ni-repeat-v"></em>
                      </span>
                    </div>
                    <div class="dropdown-body">
                      <div class="nk-notification" style={{ minHeight: "300px" }}>
                        {/* Dropdown items */}
                        {notifications.length !== 0 ? (
                          notifications.map((n, i) => {
                            return (
                              <div
                                key={i}
                                class="nk-notification-item dropdown-inner"
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: n?.is_read ? "#fff" : "#bddfff",
                                  position: "relative",
                                }}
                                onClick={() => {
                                  handleNavigation(n)
                                  handleNotificationStatus(n.id)
                                }
                                }                      >
                                <div class="nk-notification-content">
                                  <div
                                    class="nk-notification-text"
                                    style={{
                                      fontSize: "1.1em",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {n.title}
                                  </div>
                                  <div class="nk-notification-text">
                                    {n.message}
                                  </div>
                                  <div class="nk-notification-time">
                                    {moment(new Date(n.created_at)).format(
                                      "Do MMMM YYYY"
                                    )}
                                  </div>
                                </div>
                                {!n?.is_read && (
                                  <div
                                    style={{
                                      height: "6px",
                                      width: "6px",
                                      borderRadius: "50%",
                                      backgroundColor: "#036ffc",
                                      position: "absolute",
                                      top: "10px",
                                      right: "10px",
                                    }}
                                  ></div>
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <>
                            <div class="nk-notification-item dropdown-inner">
                              <div class="nk-notification-content">
                                <div class="nk-notification-text text-secondary">
                                  Empty
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div class="dropdown-foot center">
                      <Link to="/tickets">View All</Link>
                    </div>
                  </div>
                </div>
                {/* ) : (
                            ""
                        )} */}

                <li className="dropdown user-dropdown" id="button-button-dropdown">
                  <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown">
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt"></em>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-md dropdown-menu-end">
                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                      <div className="user-card">
                        <div className="user-avatar">
                          <span>{first_name?.charAt(0) + last_name?.charAt(0)}</span>
                        </div>
                        <div className="user-info">
                          <span className="lead-text">{first_name} {last_name}</span>
                          <span className="sub-text">{email}</span>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list" style={{ listStyle: "none" }}>
                        {/* <li>
                          <Link to="/profile"><em className="icon ni ni-user"></em><span>View Profile</span></Link>
                        </li> */}
                        <li id="button-button-signout"><a href="javascript:void(0)" onClick={handleLogout}>
                          <em className="icon ni ni-signout"></em><span>Sign out</span></a></li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <WelcomeMessage />
    </>
  )
};

export default Header;