import React, { useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "../../Context/DashboardContext";
import { VARIABLES } from "../../Constant";
import axios from "axios";

const WelcomeMessage = () => {
  const first_name = secureLocalStorage.getItem("firstName");
  const last_name = secureLocalStorage.getItem("lastName");
  const privileges = secureLocalStorage.getItem("privileges");

  console.log("first_name: ", first_name);
  console.log("last_name: ", last_name);

  const navigate = useNavigate();

  // const {
  //   notifications,
  //   unreadNotificationsCount,
  //   handleNotificationStatus,
  //   updateNotificationStatus,
  //   setUnreadNotificationsCount,
  //   retryCount,
  //   getNotifications,
  //   getAllNotifications,
  // } = useContext(NotificationsContext);

  const { rawPendingOnYou, getPendingOnYouData, isPendingOnYouLoading } = useContext(DashboardContext);

  const handleNavigation = () => {
    console.log("first");
    navigate("/pendingonyou");
  };

  // useEffect(() => {
  //   setUnreadNotificationsCount(notifications.length);
  // }, [notifications]);

  // useEffect(() => {
  //   console.log(retryCount);
  //   if (retryCount !== 0) {
  //     // enqueueSnackbar("No connection!", {
  //     //   anchorOrigin: {
  //     //     vertical: "bottom",
  //     //     horizontal: "center",
  //     //   },
  //     //   variant: "error",
  //     //   persist: true,
  //     //   preventDuplicate: true,
  //     // });
  //   } else {
  //     // closeSnackbar();
  //   }
  // }, [retryCount]);

  // useEffect(() => {
  //   // getNotifications();
  //   // getAllNotifications();
  //   // getPendingOnYouData(userID);
  // }, []);

  const checkQoutesData = secureLocalStorage.getItem('checkQoutesData');
  console.log(checkQoutesData)

  const [quotesData, setQuotesData] = useState([])
  console.log(quotesData)

  const getQoutes = async () => {
    let url = VARIABLES.url + `/api/quotes`;
    console.log(url)
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
    };

    try {
      const res = await axios(config);
      console.log(res.data.data)
      setQuotesData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const quotesHeader = [
    "Daily Inspiration",
    "Motivational Thought",
    "Positive Vibes",
    "Mindful Moment",
    "Words to Inspire",
    "Your Daily Spark",
    "Inspiration for Today"
  ]

  const [quote, setQuote] = useState("");
  console.log(quote)
  const [quoteHeader, setQuoteHeader] = useState("");

  const [showToast, setShowToast] = useState(true);

  const handleClose = () => {
    setShowToast(false);
  };

  useEffect(() => {
    // Generate a random index
    const randomIndex = Math.floor(Math.random() * quotesData.length);
    console.log("randomIndex: ", randomIndex)
    setQuote(quotesData[randomIndex]);

    const randomQuoteHeaderIndex = Math.floor(Math.random() * quotesHeader.length);
    console.log("randomQuoteHeaderIndex: ", randomQuoteHeaderIndex)
    setQuoteHeader(quotesHeader[randomQuoteHeaderIndex]);
  }, [quotesData]);

  useEffect(() => {
    if (checkQoutesData === null || checkQoutesData?.length === 0) {
      console.log("first")
      getQoutes();
    }
  }, [])

  useEffect(() => {
    if (quotesData?.length !== 0) {
      secureLocalStorage.setItem('checkQoutesData', quotesData)
      console.log("local")
    }
  }, [quotesData])

  useEffect(() => {
    if (checkQoutesData !== null && checkQoutesData?.length !== 0) {
      console.log("set")
      setQuotesData(checkQoutesData)
    }
  }, [checkQoutesData])

  return (
    <>
      <div className="nk-block-between ps-5 pe-5">
        <div className="nk-block-head-content w-100 d-flex align-items-center justify-content-between">
          <div className="group">
            <h3 className="nk-block-title page-title">
              {`Hello, ${first_name
                ? first_name[0].toUpperCase() + first_name.slice(1)
                : ""
                } ${last_name ? last_name[0].toUpperCase() + last_name.slice(1) : ""
                }!`}
            </h3>
            <p className="py-0">Check your daily tasks & Schedules</p>
          </div>

          <div className="d-flex">
            {/* PENDING ON YOU */}

            {/* Quotes */}
            {quote && showToast && (
              <div className="toast"
                style={{ marginTop: "20px" }}>
                <div className="toast-header">
                  <strong className="me-auto">{quoteHeader || "Wellness qoute"}</strong>
                  <button type="button" className="btn-close" onClick={handleClose}></button>
                </div>
                <div className="toast-body">{quote} </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeMessage;
