import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";
import secureLocalStorage from "react-secure-storage";

export const UserDetailsContext = createContext(null);

function UserDetailsProvider({ children }) {
  const userID = secureLocalStorage.getItem("userID");

  const [userDetails, setUserDetails] = useState([]);
  const [educationalDetails, setEducationalDetails] = useState([]);
  const [isEducationalDataLoading, setisEducationalDataLoading] = useState(false);

  const getUserDetailsData = async (userID) => {
    try {
      const url = `/api/userdetails/?user_id=${userID}`;
      console.log(url);
      const res = await axios.get(VARIABLES.url + url);
      console.log("USER DETAILS:", res.data.data);
      setUserDetails(res.data.data);
      console.log(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getEducationalDetails = async (candidate_job_id) => {
    const url =
      VARIABLES.url +
      `/api/career-educationdetails/?candidate_job=${candidate_job_id}`;
    console.log(url);
    try {
      setisEducationalDataLoading(true);
      const res = await axios.get(url);
      console.log("res", res.data);
      setEducationalDetails(res.data.data);
      setisEducationalDataLoading(false);
    } catch (error) {
      setisEducationalDataLoading(false);
      console.log("error:", error);
    }
  };

  const [candidateData, setCandidateData] = useState([])

  const candidate_to_be_process_id = secureLocalStorage.getItem("candidate_info");

  const getProfessionalDetails = async () => {
    console.log("candidate_job: candidate to be process: ", candidate_to_be_process_id)
    try {
      const url = `/all-candidate-details-career/?candidate_to_be_process_id=${candidate_to_be_process_id}`;
      console.log(url);
      const res = await axios.get(VARIABLES.url + url);
      console.log("res", res.data);
      setCandidateData(res.data);
    } catch (error) {
      console.log("error:", error);
    }
  };



  const setActiveTab = (tab) => {
    secureLocalStorage.setItem("active_tab", tab)
  }

  return (
    <div>
      <UserDetailsContext.Provider
        value={{
          userDetails,
          getUserDetailsData,
          educationalDetails,
          setUserDetails,
          getEducationalDetails,




          candidateData,
          getProfessionalDetails,
          setCandidateData,
          setActiveTab
        }}
      >
        {children}
      </UserDetailsContext.Provider>
    </div>
  );
}

export default UserDetailsProvider;
