import React from 'react';
import './app.css'
import { demo } from './demo'

function CandidateCabinet() {

    const getStageColor = (innerStepClass, stage, stage_status) => {
        if (innerStepClass === 'progress-step-inner') {
            return '#dcdcdc';
        } else {
            if (stage_status === 'Not Shortlisted') {
                return '#fc2121'; //RED
            } else {
                return stage === 1 ? '#ff7800' : stage === 2 ? '#ffff00' : stage === 3 ? '#1fab3e' : '#dcdcdc';
            }
        }
    };

    const getStageBorder = (stepClass, stage, stage_status) => {
        if (stepClass === 'progress-step') {
            return '#dcdcdc';
        } else {
            if (stage_status === 'Not Shortlisted') {
                return '2px solid #fc2121'; //RED
            } else {
                return stage === 1 ? '2px solid #ff7800' : stage === 2 ? '2px solid yellow' : stage === 3 ? '2px solid #1fab3e' : '#dcdcdc';
            }
        }
    }

    return (
        <>
            <div class="card">
                <div class="card-inner-group p-4">
                    <h6 class="h6">Hiring Pipeline</h6>

                    <div class="card mt-4" style={{ borderRadius: "0px", border: "1px solid #c2c2c2" }}>
                        <div className='progress-percent'>
                            <div class="loading-container">
                                <div class="loading-bar" style={{ width: `${demo.total_progress}%` }}>
                                    <div class="percentage" style={{ right: demo.total_progress === 0 ? "-25px" : "-2px" }}>
                                        <span>{demo.total_progress}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='progressbody d-flex overflow-x-auto'>
                            {demo.form.map((key) => (
                                <div className='progress-section' >
                                    <h5 style={{ textWrap: "nowrap" }}>{key.name}</h5>
                                    <div className="progressbar">
                                        {[1, 2, 3].map((index) => {
                                            const stepClass = index <= key.stage ? `progress-step-stage-${key.stage}` : 'progress-step';
                                            const innerStepClass = index <= key.stage ? `progress-step-inner-stage-${key.stage}` : 'progress-step-inner';
                                            return (
                                                <div className={stepClass} key={index} style={{ border: getStageBorder(stepClass, key.stage, key?.stage_status) }}>
                                                    <div className={innerStepClass} style={{ backgroundColor: getStageColor(innerStepClass, key.stage, key?.stage_status) }}></div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <p style={{ color: getStageColor('progress-step-inner-stage', key?.stage, key?.stage_status), fontSize: "12px", fontWeight: "500", textWrap: "nowrap" }}>{'(' + key.stage_status + ')'}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateCabinet;
